.contentIntro{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#intro{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 1000;
    background-color: var(--bgColor);

    opacity: 100;
    transition: all 1s ease;
}
#intro.hide{
    display: none;
}
.stream{
    opacity: 90%;
    transition: opacity 1s ease;
}
.welcomeDiv{
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	color: var(--lightColor);
    z-index: 1;
    font-family: var(--titleFont);
    font-size: 30px;
    z-index: 1001;
    transition: all 1s ease;
    opacity: 100;
    position: relative;
    text-align: center;
}
.welcomeDiv > p {
    margin: 0;
    z-index: 11;
    margin-top: 15px;
    text-align: center;
}
#logo {
	width: 20vw;
	border-radius: 100%;
	border: 3px solid var(--lightColor);
    z-index: 11;
}
.logoBackground {
	position: absolute;
	width: 100vw;
    height: 12vw;
    background-color: rgba(255, 255, 255, 0.7);
    top: 20%;
	
    border-top: 1px solid var(--primaryColor);
    /* border-bottom: 1px solid var(--primaryColor); */
}
.logoBackgroundWide {
	position: absolute;
	width: 100vw;
    height: 35px;
    background-color: var(--primaryColor70procent);
    bottom: 0;
    
    border-top: 1px solid var(--lightColor);
    /* border-bottom: 1px solid var(--lightColor); */
}
.nullOpacity{
    opacity: 0%;
}