.upBtn{
	display: none;
}
.navbar {
	position: fixed;
	font-family: var(--titleFont);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 100vh;
	width: 10vw;
	opacity: 100%;
	transition: all .5s ease;
	background-color: var(--secondaryColor);
	z-index: 111111;
	flex-direction: column;
	background: linear-gradient(to right, #2fb94b6b, rgba(40, 255, 16, 0.01));
	min-width: 90px;
	max-width: 120px;
}
.titleBox{
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--lightColor);
    gap: 20px;
	position: absolute;
	top: 2.5%;
}
.icon {
	width: 3vh;
	min-width: 75px;
	border-radius: 100%;
	border: 1px solid var(--lightColor);
	z-index: 11;
	cursor: pointer;
}
.navbarTitle{
    border-left: 1px solid var(--primaryColor);
    padding-left: 10px;
}
.links {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	width: 40%;
	height: 60%;
	padding: 0;
	margin: 0;
}
.link {
	color: var(--lightColor);
	cursor: pointer;
	height: 60px;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	text-decoration: none;
	/* height: 100%; */
	/* width: 100%; */
}
.link:hover{
    color: var(--primaryColor);
}
.selected {
	color: var(--primaryColor);
	border: 1px solid var(--primaryColor);
	border-radius: 40px;
	background: var(--lightColor);
}
.biggerSVG > svg {
	height: 1.15em;
	
}
.upButton {
	position: fixed;
	bottom: 10%;
	right: 10%;
	color: var(--lightColor);
}
.nullOpacity{
    opacity: 0;
}
.hide{
    display: none;
}

@media screen and (max-width: 665px)  {
	.navbar{
		display: none;
	}
	.upBtn{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		position: fixed;
		bottom: 3%;
		left: 7%;
		z-index: 11212121212;
		
		border: none;
		border-radius: 100%;
		height: 40px;
		width: 40px;
		cursor: pointer;
		background: linear-gradient(to right, #2fb94b6b, rgba(40, 255, 16, 0.01));
		color: var(--primaryColor);
		transition: all .4s ease;
	}
	.upBtn:hover{
		color: var(--darkColor);
		background-color: var(--primaryColor);
		transform: scale(1.25);
	}
	.upBtn > svg{
		font-size: 38px;
	}
}