.projectsContent {
	display: flex;
	align-items: end;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	font-family: 'Times New Roman', Times, serif;
	opacity: 0;
	transition: all .4s ease;
}
.projectsContainer {
	width: 90%;
	height: 80%;
	margin-top: 10%;
}
.projectsTitle {
	color: var(--lightColor);
	-webkit-user-select: none;
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-size: 40px;
	font-family: var(--titleFont);
	width: 100%;
	text-align: center;
	margin-top: 0;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: end;
	height: 10%;
}
.projectsContainer > p {
	text-align: center;
}
.projectsContainer > p > a {
	text-decoration: none;
	color: var(--primaryColor);
}
.projectsContainer > p > a:hover {
	text-decoration: underline;
	font-weight: bolder;
}
.projectsView {
	width: 100%;
	display: grid;
	justify-content: center;
	align-items: center;
	overflow: scroll;
	gap: 3%;
	height: 85%;
	grid-template-columns: repeat(4, auto);
  	padding-top: 1%;
	padding-bottom: 1%;
  	grid-template-rows: repeat(4,auto);
}
.selectedProject {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}
.projectBox {
	transition: all .4s ease;
	/* background: var(--secondaryColor); */
	display: flex;
	justify-content: center;
	align-items: center;
	/* max-height: 170px; 
	max-width: 200px; */
	width: 15vw;
	height: 15vw;
	border-radius: 20px;
	position: relative;

	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	box-shadow: 0px 0px 7px 1px var(--primaryColor70procent);
}
.projectBox:hover {
	transform: scale(1.1);
}
.projectBox:hover > .projectBoxNameP {
	display: none;
}
.projectBoxNameP{
	margin: 0;
	text-align: center;
	z-index: 12;
	font-size: 17px;
	background-color: var(--secondaryColor);
	padding-top: 5px;
	padding-bottom: 5px;
}
.darkTilt {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	background: var(--darkColor);
	opacity: 80%;
	border-radius: 20px;
	transition: all .4s ease;
}
.darkTilt:hover {
	opacity: 0%;
	/* background: rgba(255,255,255, 0.5); */
}
.selectedProjectContent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 80%;
	/* height: 80%; */
	height: 90%;
	gap: 2%;
	position: relative;
	flex-direction: column;
  	padding-top: 50px;
}
.backToProjectsBtn {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	font-size: 20px;
	background: linear-gradient(to right, #2fb94b6b, rgba(40, 255, 16, 0.01));
	padding: 10px;
	border-radius: 15px;
	cursor: pointer;
	font-family: var(--baseFont);
	color: var(--lightColor);
	text-decoration: none;
}
.backToProjectsBtn:hover{
	font-weight: bolder;
	background: linear-gradient(to right, #0cd634ec, rgba(40, 255, 16, 0.01));
}
.backToProjectsBtn > svg {
	font-size: 16px;
	margin-right: 5px;
}
.projectInfo {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	/* height: 60%; */
	/* width: 40%; */
	height: 40%;
	width: 70%;
	gap: 15px;
	overflow: scroll;
}
.imgContent {
	width: 70%;
	max-width: 1000px;
	position: relative;
}
.imgNavigateBtn{
	position: absolute;
	border: none;
	color: var(--lightColor);
	border-radius: 100px;
	font-size: 30px;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all .4s ease;
	opacity: 70%;
	bottom: 15px;
	background: none;
}
.imgNavigateBtn:hover{
	opacity: 100%;
}
.preImg{
	left: 7px;
	/* background:linear-gradient(to right, #f8f9f86b, rgba(40, 255, 16, 0.01)); */
}
.nextImg {
	right: 7px;
	/* background: linear-gradient(to left, #f8f9f86b, rgba(40, 255, 16, 0.01)); */
}
.selectedProjectImg {
	width: 100%;
	max-height:1100px;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
}
.selectedProjectName{
	margin: 0;
}
.selectedProjectDesc{

}
.selectedProjectLink {
	margin: 0;
	font-size: 20px;
	background: linear-gradient(to left, #2fb94b6b, rgba(40, 255, 16, 0.01));
	padding: 10px;
	border-radius: 15px;
	cursor: pointer;
	font-family: var(--baseFont);
	position: absolute;
  	top: 0;
  	right: 0;
	color: var(--lightColor);
	text-decoration: none;
}
.selectedProjectLink:hover{
	font-weight: bolder;
	background: linear-gradient(to left, #0cd634ec, rgba(40, 255, 16, 0.01));
}
.selectedProjectLink > svg {
	font-size: 16px;
	margin-left: 5px;
}
.selectedProject span{
	font-weight: bolder;
	color: var(--primaryColor);
}
.navigatorProjects {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: absolute;
	bottom: 3%;
	z-index: 2222222;
	height: 8%;
}
.selectedProjectTitle{
	margin: 0;	
}
.navigateBtn {
	border: none;
	color: var(--lightColor);
	border-radius: 100px;
	font-size: 20px;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all .4s ease;
	position: absolute;
}
.navigateBtn:hover{
	font-weight: bolder;
}
.preProjectBtn{
	left: 10%;
	background:linear-gradient(to right, #2fb94b6b, rgba(40, 255, 16, 0.01));
}
.preProjectBtn:hover{
	background: linear-gradient(to right, #0cd634ec, rgba(40, 255, 16, 0.01));
	z-index: 2222223;
}
.nextProjectBtn{
	right: 10%;
	background: linear-gradient(to left, #2fb94b6b, rgba(40, 255, 16, 0.01));
}
.nextProjectBtn:hover{
	background: linear-gradient(to left, #0cd634ec, rgba(40, 255, 16, 0.01));
}

.navigateDots{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 5%;
	position: absolute;
  	bottom: 0;

	width: 20%;
}
.dot{
	background-color: var(--lightColor);
	width: .5vw;
	height: .5vw;
	border-radius: 100%;
	opacity: 0.5;
	cursor: pointer;

	transition: all .4s ease;
}
.dot:hover{
	opacity: 1;
	transform: scale(1.25);
}
.activeDot{
	opacity: 1;
	transform: scale(1.5);
}

@media screen and (min-width: 1340px)  {
	.selectedProjectContent{
		flex-direction: row;
		padding-top: unset;
	}
	.projectInfo{
		height: 45%;
  		width: 40%;
	}
}

@media screen and (max-width: 665px)  {
	.projectsContainer{
		overflow: scroll;
		height: 100%;
	}
	.projectsContent{
		height: 90%;
		position: unset;
	}
	.projectsTitle{
		align-items: center;
	}
	.projectsView{
		display: flex;
    	flex-direction: column;
		height: unset;
		gap: 20px;
	}
	.projectBox{
		width: 80vw;
		height: 30vh;
	}
	.backToProjectsBtn{
		font-size: 15px;
		top: 15px;
		left: 10px;
	}
	.selectedProjectLink{
		font-size: 15px;
		top: 15px;
		right: 10px;
	}
	.selectedProject{
		height: 95%;
		position: unset;
	}
	.selectedProjectContent{
		flex-direction: column;
		width: 95%;
		/* height: 85%; */
		max-height: 90%;
		position: unset;
		padding-top: unset;
	}
	.selectedProjectName{
		display: none;
	}
	.imgContent{
		width: 90%;
	}
	.projectInfo{
		width: 90%;
		height: auto;
	}
	.navigatorProjects{
		bottom: 3.5%;
	}
	.navigateBtn{
		display: none;
	}
	.navigateDots{
		width: 50%;
	}
	.dot{
		width: 2vw;
  		height: 2vw;
	}
}