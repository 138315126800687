/* .contactLogos {
	gap: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 6.5%;
	height: 100%;
    background-color: var(--secondaryColor);
} */
.contactTitle {
	color: var(--lightColor);
	-webkit-user-select: none;
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-size: 40px;
	font-family: var(--titleFont);
	width: 100%;
	text-align: center;
	margin-top: 0;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: end;
	height: 10%;
}
.contactLogos {
	flex-direction: column;
	width: 6.5%;
	height: 100%;
	display: flex;
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-family: var(--titleFont);
	justify-content: center;
	position: absolute;
	right: 0;
	align-items: center;
	z-index: 1111;
	gap: 3vw;
	background: linear-gradient(to left, #2fb94b6b, rgba(40, 255, 16, 0.01));
}
.contactLogo {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 60px;
	width: 60px;
	border-radius: 10px;
	border: 1px solid var(--lightColor);
	filter: grayscale(70%);
	transition: all .3s ease;
	cursor: pointer;
	margin-right: 30px;
}
.contactLogo:not(.email):hover {
    filter: grayscale(0);
    transform: scale(1.25);
    z-index: 1;
}
.instagram{
    background-image: url("/src/assets/logos/instagram1.jpg");
}
.facebook{
    background-image: url("/src/assets/logos/facebook3.jpg");
}
.linkedIn{
    background-image: url("/src/assets/logos/linkedin.jpg");
}
.github{
    background-image: url("/src/assets/logos/github2.jpg");
}
.email{
    grid-column: 3/3;
    grid-row: 1/3;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    border: none;
}
.emailDiv{
	display: flex;
    justify-content: space-evenly;
	align-items: center;
	height: fit-content;
	flex-direction: row;
    width: 80%;
    gap: 10px;
    height: 40%
}
.contactDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all .4s ease;
	/* background-color: var(--secondaryColor);
	background-image: url(http://localhost:3000/static/media/rain_stream.8a21ba91047a3e90f85b.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover; */
}
.mail_info {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: 100%;
	width: 88%;
    /* background-image: url("../assets/images/rain_stream.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    font-family: times;
}
.info {
    width: 80%;
	max-height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}
/* .info {
	width: 80%;
	max-height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--secondaryColor);
	border-radius: 30px;
	padding: 25px;
	box-shadow: 0px 0px 10px var(--primaryColor70procent);
} */
.info > h1, h3, p {
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
}
.info span{
    font-weight: bolder;
    color: var(--primaryColor);
}
.info span a{
	text-decoration: none;
	color: var(--primaryColor);
}
.info > p {
    font-size: 18px;
}
.infoLogos {
	gap: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 10%;
}
.infoLogo {
	width: 4vw;
	cursor: pointer;
	transition: all .3s ease;
	border: 1px solid var(--lightColor);
	border-radius: 10px;
	filter: grayscale(70%);
	min-width: 60px;
	max-width: 80px;
}
.infoLogo:hover{
    transform: scale(1.15);
    filter: grayscale(30%);
}
.infoLogo.selected{
    transform: scale(1.25);
    filter: grayscale(0%);
    border-color: var(--primaryColor);
}
.contactForm {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	width: 30%;
	position: relative;
	background-color: var(--secondaryColor);
	padding: 25px;
	border-radius: 30px;
	box-shadow: 0px 0px 10px var(--primaryColor70procent);
}
.contactForm > input, textarea{
    width: 100%;
    height: 20px;
}
.contactForm textarea{
    height: 20vh;
    max-width: 100%;
    max-height: 180px;
}
.sendBtn {
	background-color: var(--lightColor);
	text-decoration: none;
	border-radius: 10px;
	padding: 10px;
	color: var(--darkerColor);
	font-weight: bolder;
	box-shadow: 1px 1px 10px var(--darkerColor);
	border: none;
    transition: all .3s ease;
    cursor: pointer;
	width: unset;
}
.sendBtn:hover{
    background-color: var(--primaryColor);
    color: var(--lightColor);
}
.responseMessage{
    text-align: center;
    margin: 0;
    margin-top: 10px;
}
.contactLogosSmaller{
	display: none;
}

@media screen and (max-width: 665px)  {
	/* Hidings */
	.contactLogos{
		display: none;
	}

	/*New Css*/
	.contactTitle{
		align-items: center;
	}
	.mail_info{
		align-items: center;
		overflow: scroll;
	}
	.contactLogosSmaller{
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 15px;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}
	.contactLogo{
		margin: 0;
		height: 50px;
		width: 50px;
	}
	.info{
		width: unset;
		max-height: unset;
		overflow: scroll;
		height: 70%;
		justify-content: start;
	}
	.info > p {
		margin-bottom: 0;
	}
	.infoLogos{
		bottom: 4.5%;
	}
	.infoLogo{
		width: 3vw;
	}
	.contactForm{
		width: 80%;
	}
}