.cvContent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: 100%;
	width: 100%;
    position: relative;
    font-family: 'Times New Roman', Times, serif;
    transition: all .4s ease;
    opacity: 0;
}
/* .navigator {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 10%;
	gap: 10%;
	z-index: 12;
} */
.navigator {
	display: flex;
	writing-mode: vertical-lr;
	text-orientation: upright;
	white-space: nowrap;
	-webkit-user-select: none;
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-family: var(--titleFont);
	justify-content: space-evenly;
	height: 100vh;
	width: 5vw;
	background: linear-gradient(to left, #08601a6b, rgba(40, 255, 16, 0.01));
	position: absolute;
	right: 0;
	align-items: center;
	z-index: 1111;
}
.navigateLink{
    cursor: pointer;
    font-size: 20px;
    transition: color .3s ease;
    font-weight: bolder;
}
.navigateLink:hover{
    color: var(--primaryColor);
    /* border-left: 1px solid var(--primaryColor); */
    padding-right: 10px;
}
.selectedContentLink {
	color: var(--primaryColor);
	border-right: 3px solid var(--primaryColor);
	padding-right: 10px;
}
/* .cvSelectedTitle {
	position: absolute;
	right: 0;
	color: var(--lightColor);
	writing-mode: vertical-lr;
	text-orientation: upright;
	white-space: nowrap;
	-webkit-user-select: none;
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-size: 25px;
	margin: 0;
	font-family: var(--titleFont);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 100vh;
	width: 5vw;
	background: linear-gradient(to left, #08601a6b, rgba(40, 255, 16, 0.01));
    z-index: 111111;
} */
.cvSelectedTitle {
	color: var(--lightColor);
	user-select: none;
	text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.97);
	font-size: 40px;
	margin: 0;
	font-family: var(--titleFont);
	display: flex;
	justify-content: space-evenly;
	align-items: end;
	height: 10%;
	width: 100%;
}
.aboutDivs {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
    position: absolute;
}
.contentInner {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 12;
}
.aboutDiv {
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 3vw;
}
/* .cvContent > .sideImgCV {
	width: 35%;
	height: 100%;
    background-image: url("../assets/images/cvSide.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	right: 0;
	opacity: 80%;
	user-select: none;
    pointer-events: none;
	overflow-x: hidden;
} */
.cvContent > .sideImgCV {
	width: 55%;
	height: 100%;
	background-image: url("../assets/images/cvSide.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	right: -170px;
	opacity: 80%;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
	overflow-x: hidden;
}
/* .sideImgCV > img {
	width: 100%;
	position: absolute;
} */
.darkFilter{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    /* background: linear-gradient(to right, #0e8c00a3, rgba(40, 255, 16, 0.01)); */
    /* background: linear-gradient(to right, #000c, rgba(40, 255, 16, 0.01)); */
    background: linear-gradient(to right, #000000de, rgba(40, 255, 16, 0.01));
}
/* .skillsDiv > ul li:nth-child(even) {
    background: linear-gradient(to right, var(--secondaryColor), rgba(255, 255, 255, 0));
} */

/* Education part */
.skillsContent,
.educationContent,
.experienceContent{
	display: flex;
	flex-direction: row;
	width: 90%;
	height: 100%;
	align-items: center;
	justify-content: space-around;
	margin-left: 5%;
}
.skillsBoxes,
.educationBoxes,
.experienceBoxes {
	padding: 0;
	margin: 0;
	width: 40%;
	display: flex;
	flex-direction: column;
	height: 80%;
	justify-content: center;
	gap: 3vw;
}
.skillBox,
.educationBox,
.experienceBox{
	border-bottom: 1px solid var(--secondaryColor);
	background-color: rgba(0,0,0,0.5);
	cursor: pointer;
	transition: all .4s ease;
}
.skillBox:hover,
.educationBox:hover{
	border-color: var(--lightColor);
	margin-left: 10px;
}
.skillBox{
	padding: 10px;
	display: flex;
  	justify-content: space-between;
  	align-items: center;
}
.educationBox{
	display: flex;
  	justify-content: space-between;
	padding-bottom: 10px;
}
.skillBoxTitle,
.educationBoxTitle,
.experienceBoxTitle{
	margin-top: 0;
	margin-bottom: 15px;
	color: var(--primaryColor);
	transition: all .4s ease;
}
.skillBoxTitle {
	color: var(--lightColor);
	margin: 0;
}
.aboutDiv.educationDiv{
	flex-direction: row;
	gap: 0;
	height: 90%;
}
.selectedSkill > .skillBoxTitle{
	color: var(--primaryColor);
}
.selectedSkillContent,
.selectedEducationContent {
	width: 48%;
	max-height: 70%;
	display: flex;
	justify-content: start;
	font-size: 18px;
	flex-direction: column;
	overflow-y: scroll;
	gap: 10px;
}
/* Targeting the marker of the li element */
.selectedEducationContent > ul li::marker {
	color: var(--primaryColor);
}
.selectedSkill,
.selectedSchool{
	border-color: var(--lightColor);
	border-end-end-radius: 20px;
	margin-left: 25px;
}
.selectedSchool:hover,
.selectedSkill:hover{
	margin-left: 25px;
}
.selectedSkillContent span,
.selectedEducationContent span{
	font-weight: bolder;
	font-size: 25px;
	color: var(--primaryColor);
}
.skillLogo{
	width: 20%;
}
/* Resumé part */
.resumeContent {
	width: 80%;
	height: 90%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.resumeContent > img {
	/* filter: hue-rotate(140deg); */
	max-width: 40%;
	user-select: none;
    pointer-events: none;
}
.resumeContent > div {
	width: 35%;
	font-size: 18px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
}
.resumeContent > div > h2 {
	margin: 0;
	color: var(--primaryColor);
}
.resumeContent > div > h4 {
	margin: 10px 0px 20px 0px;
}
.downloadButton {
	text-decoration: none;
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	color: var(--lightColor);
	background-color: var(--secondaryColor);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.4s ease;
	width: fit-content;
}
.downloadButton:hover {
	background-color: var(--primaryColor); /* Adjust hover background color */
}
  .downloadButton > svg{
	margin-right: 5px;
  }

/* For Chrome and Safari */
/* Track */
::-webkit-scrollbar {
	width: 0px; /* width of the entire scrollbar */
	background-color:rgba(255,255,255, 0);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background-color:rgba(255,255,255, 0); /* color of the scrollbar handle */
	border-radius: 6px; /* roundness of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background-color:rgba(255,255,255, 0); /* color of the scrollbar handle */ /* color of the scrollbar handle on hover */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background-color:rgba(255,255,255, 0); /* color of the scrollbar handle */; /* color of the scrollbar track */
  }
  
  /* Handle when it's dragging */
  ::-webkit-scrollbar-thumb:active {
	background-color:rgba(255,255,255, 0); /* color of the scrollbar handle */ /* color of the scrollbar handle when dragging */
  }
  
/* Add the following rule for Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(255,255,255, 0) rgba(255,255,255, 0); /* Scrollbar Track and Handle Colors */
  }
  
  /* Handle */
  /* You can style the scrollbar handle further using the ::-moz-scrollbar-thumb pseudo-element */
  *::-moz-scrollbar-thumb {
	background-color: rgba(255,255,255, 0); /* Handle Color */
  }
  
  /* You can also style the scrollbar track using the ::-moz-scrollbar-track pseudo-element */
  *::-moz-scrollbar-track {
	background-color: rgba(255,255,255, 0); /* Track Color */
  }
  

/*Education Part*/
.experienceContent{
	justify-content: center;
	margin: 0;
}
.experienceBoxes{
	width: 80%;
}
.experienceBox{
	cursor: unset;
	/* background-color:rgba(0,0,0, 0.1); */
	background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
}
.educationImg{
	background-color: var(--lightColor);
	width: 80px;
	height: 80px;
	border-radius: 100px;
}
.experienceBox > p{
	font-size: 18px;
	width: 99%;
}
.educationBox > div > h2 {
	text-align: right;
}
.educationBox > div > h3 {
	text-align: right;
}
/* Hides the css codes for mobile  */
.skillsContentMobile,
.educationContentMobile{
	display: none;
}

@media screen and (max-width: 665px)  {

	/* Hides the css codes for larger screens */
	.skillsContent,
	.educationContent {
		display: none;
	}

	/* Generall for cv page */
	.aboutDiv{
		align-items: start;
	}
	.cvDiv{
		justify-content: start;
	}
	.cvContent > .sideImgCV{
		right: -185px;
	}
	.navigator{
		width: 45px;
	}

	/* CV content */
	.resumeContent {
		width: 100%;
		/* height: 100%; */
		max-height: 88%;
		flex-direction: column;
		overflow: scroll;
		justify-content: start;
		gap: 3%;
		margin-top: 1vh;
	}
	.resumeContent > img {
		max-width: 25vh;
	}
	.resumeContent > div {
		width: 70%;
		align-items: center;
	}
	.resumeContent > div > h2 {
		font-size: 25px;
	}
	.cvSelectedTitle{
		align-items: center;
	}

	/* Skills Content */
	.skillsContentMobile,
	.educationContentMobile {
		display: flex;
		flex-direction: column;
		margin: 0;
		width: 100%;
		height: 100%;
		align-items: center;
	}
	.skillsBoxes,
	.educationBoxes{
		width: 80%;
		height: 90%;
    	justify-content: center;
    	gap: 6%;
	}
	.skillBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 11px;
		width: 85%;
		border-top: 1px solid var(--lightColor);
		border-color: var(--lightColor);
		border-start-start-radius: 50px;
    	border-end-start-radius: 50px;
    	padding: 8px;
	}
	.skillLogo{
		width: 30%;
    	border-radius: 80px;
	}
	.skillLogoBig{
		width: 75%;
		border: 1px solid var(--lightColor);
    	border-radius: 20px;
	}
	.skillBoxTitle{
		color: var(--primaryColor);
	}
	.selectedSkillContent,
	.selectedEducationContent {
		width: 80%;
		height: 100%;
		justify-content: space-evenly;
    	align-items: center;
	}
	.selectedSkillContent > p {
		width: auto;
		margin-bottom: 0;
		padding-bottom: 2px;
    	border-bottom: 1px solid var(--primaryColor70procent);
	}
	.selectedSkillContent > ul {
		width: 100%;
  		margin: 0;
		font-size: 16px;
		display: flex;
    	flex-direction: column;
    	gap: 5px;
	}
	.backBtn{
		border: none;
		position: absolute;
		top: 8%;
		left: 5%;
		color: var(--lightColor);
		background: linear-gradient(to right, #2fb94b6b, rgba(40, 255, 16, 0.01));
		border-radius: 100px;
		font-size: 24px;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	/* Education Content */
	.educationBoxes{
		justify-content: center;
	}
	.educationBox{
		width: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid var(--lightColor);
		border-color: var(--lightColor);
		border-start-start-radius: 50px;
    	border-end-start-radius: 50px;
    	padding: 5px;
	}
	.educationImg{
		background-color: var(--lightColor);
		width: 80px;
		height: 80px;
		border-radius: 100px;
	}
	.educationBox > div > h2 {
		font-size: 18px;
	}
	.educationBox > div > h3 {
		font-size: 14px;
	}
	.selectedEducationContent{
		max-height: 90%;
		justify-content: start;
	}
	.selectedEducationContent > p {
		width: auto;
	}

	/*Experience content*/
	.experienceContent{
		align-items: start;
		height: 90%;
	}
	.experienceDiv{
		height: 90%;
	}
	.experienceBoxes{
		height: 90%;
	}
	.experienceBox{
		max-height: 95%;
  		overflow: scroll;
		padding-bottom: 10px;
	}
}