:root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
	--primaryColor: #20c20e;
	--primaryColor70procent: rgb(32, 194, 14, .7);
	--secondaryColor: rgba(40, 255, 16, 0.34);
	--thirdColor: rgb(40, 172, 202);
	--fourthColor: #dddfdf;
	--fifthColor: #CBFCFF;
	--lightColor: white;
	--darkColor: black;
	--darkerColor: #020102;
	--bgColor:#111;
	--textColor: black;

	--mSize: 20px;
	--sSize: calc(var(--mSize) * 0.5);
	--lSize: calc(var(--lSize) * 1.5);
	--xlSize: calc(var(--mSize) * 2);
	--xxlSize: calc(var(--mSize) * 5);
	--titleFont: 'matrixFont', cursive;
	--baseFont: 'matrixLetters';
}
html {
	scroll-behavior: smooth;
}
.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
	z-index: 1;
}
@font-face {
	font-family: matrixFont;
	src: url('../assets/typsnitt/matrix.ttf');
}
@font-face {
	font-family: matrixLetters;
	src: url('../assets/typsnitt/matrix-letters.ttf');
}
@font-face {
	font-family: matrixFontJapanese;
	src: url('../assets/typsnitt/matrixCodeNfi.ttf');
}

/* Text Selection color */
::selection{
	color: var(--darkerColor);
	background-color: var(--primaryColor);
}