.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--lightColor);
    font-family: var(--baseFont);
    position: relative;

	background-color: var(--darkerColor);
	height: 100vh;
	width: 100vw;
}
/* .section:nth-child(even) {
    background-image: url("../assets/images/rain_stream_rotated.png");
} */
.section > 
h1,h3{
    margin: 0;
    margin-left: 20%;
}
.welcomeBar,
.welcomeBarMobile {
	font-family: var(--titleFont);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .5s ease;
	grid-column: 1/3;
	font-size: 25px;
}
.welcomeBarMobile{
    display: none;
}
.welcomeBarTitleBox {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--lightColor);
	width: 90vw;
	border-left: 1px solid var(--lightColor);
	border-right: 1px solid var(--lightColor);
	/* background-color: rgba(40, 255, 16, 0.23); */
    background-color: var(--secondaryColor);
	background-color: var(--primaryColor70procent);
}
.welcomeBarTitle {
	margin: 0;
	padding: 10px;
}
.home {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 40px 1fr;
	width: 90vw;
	height: 90vh;
	color: var(--lightColor);
	font-size: 40px;
	gap: 4vh;
    z-index: 1;
}
.homeLink{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    box-shadow: 0px 0px 10px var(--primaryColor);
    transition: all 0.4s ease-in;
    transform: scale(1);
    filter: grayscale(20%);
}
.homeLink:hover {
	/* border: 1px solid var(--primaryColor); */
	border-radius: 40px;
	transform: scale(1.05);
	z-index: 1;
	box-shadow: 0px 0px 20px var(--primaryColor);
	filter: grayscale(none);
}
.homeLink p{
    margin: 0;
    z-index: 1;
    opacity: 100;
    transition: all .4s ease-in;
    user-select: none;
    pointer-events: none;
    width: auto;
}
.foreDiv{
    background-color: rgba(0, 0,0, .5);
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 100;
    transition: all .4s ease-in;
    filter: blur(10px);
}
.foreDiv:hover{
    opacity: 0;
}
.cv {
    background-image: url("../assets/images/home/cv.jpg");
}
.about{
    background-image: url("../assets/images/home/about.jpg");
}
.projects{
    background-image: url("../assets/images/home/projects.jpg");
}
.contact{
    background-image: url("../assets/images/home/contact.jpg");
}
.foreDivCV:hover ~ .cvP,
.foreDivAbout:hover ~ .aboutP,
.foreDivProjects:hover ~ .projectsP,
.foreDivContact:hover ~ .contactP {
    transform: scale(1.25);
	background-color: rgba(0, 0,0, .75);
	padding: 10px;
	border-radius: 20px;
	border: 1px solid var(--primaryColor);
	color: var(--lightColor);
	box-shadow: 0px 0px 8px var(--primaryColor);
    position: absolute;
    bottom: 10;
}
.backgroundIMG{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-image: url("../assets/images/rain_stream.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    opacity: 50%;
}
.footer{
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bolder;
    padding: 8px;
    /* background: var(--primaryColor70procent); */
    color: var(--lightColor);
    z-index: 1;
    position: relative;
    background: linear-gradient(to left, #03280b, rgba(0, 0, 0, 0.8));
}

/* Chat */
.aibotLogo {
	position: fixed;
	bottom: 5%;
	right: 2%;
    width: 5vw;
    border: 1px dashed var(--primaryColor);
    border-radius: 100px;
    padding: 10px;
	transition: all .3s ease-in-out;
    z-index: 11111;
    transform: translateX(200%);
}
.aibotLogo:hover {
	border-color: var(--lightColor);
	background: black;
}
.aibotLogo.right{
    transform: translateX(0%); /* Hidden by default */
}
.chatContainer {
	border-radius: 8px;
	border: 1px dashed #fafafa;
	background-color: #000000d6;
	transition: all 0.3s ease;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: scroll;
	position: fixed;
	justify-content: space-between;
	right: 1%;
	bottom: 5%;
	width: 35vw;
	z-index: 11111;
	max-height: 70vh;
	transform: translateX(120%);
	padding: 8px;
	max-width: 40vw;
	height: auto;
	min-height: 30vh;
}
.chatContainer.right {
    transform: translateX(0); /* Visible when `right` class is added */
}
.chat-box {
	border-radius: 8px;
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	height: 95%;
	gap: 15px;
	width: 100%;
	overflow: scroll;
	position: relative;
}
.newChatButton, .minusButton {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1rem;
	/* color: #0000008a; */
	color: #fff;
	background: unset;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: color .5s;
	margin: 0;
	/* width: -moz-fit-content; */
	width: fit-content;
	/* padding: 5px; */
}
.newChatButton:hover:not(:disabled), .minusButton:hover:not(:disabled){
  color: var(--primaryColor);
  /* color: #fff; */
  background: unset;
}
.minusButton{
    right: unset;
    left: 0;
}
.message {
	background: rgba(67, 63, 63, 0.63);
	padding: 1rem;
	border-radius: 8px;
	color: #fff;
	font-size: 1rem;
}
.chat-box .message-input {
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  color: black;
  font-size: 1rem;
  outline: none;
  margin-top: auto;
  margin-bottom: 0;
  transition: background 0.3s;
  font-family: var(--baseFont);
}

.message-input::placeholder {
  color: gray;
  opacity: 0.5; /* Adjust this value as needed */
}
.chat-box .message-input:focus {
  background: rgba(0, 0, 0, 0.786);
  color: white;
}
.message.user {
	text-align: right;
	display: flex;
	flex-direction: row-reverse;
	gap: 5px;
}
.message.system {
  text-align: left;
  position: relative;
}
.message.user strong{
  color: #ffffff;
}
.message.system strong{
  color: var(--primaryColor);
  margin-right: 5px;
}
.aibotIcon{
    width: 30px;
}
.chatContainer form {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 10px;
	gap: 10px;
}
form > input.message-input {
    margin-bottom: 0;
    width: 80%;
}
form > button {
	width: 20%;
	padding: 8px;
	margin: 0;
	background: var(--primaryColor);
	color: #ffffff;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	transition: background 0.3s;
}

/* dot animation */

.thinking {
  background: transparent;
  border: none;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.thinking .dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  animation: dot-flash 1.5s infinite;
}

.thinking .dot:nth-child(1) {
  animation-delay: 0s;
}

.thinking .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.thinking .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-flash {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
} 

/* Additional styles for screens 665 pixels or smaller */
@media screen and (max-width: 665px)  {

    /* Add any other styles you want to change for smaller screens */
    .home {
        display: flex;
        flex-direction: column;
    }
    .welcomeBar{
        display: none;
    }
    .welcomeBarTitleBox {
        font-size: 13px;
    }
    .welcomeBarMobile{
        display: flex;
    }
    .homeLink {
        width: 100%; /* Full width on smaller screens */
        height: 20vh;
    }
    .chatContainer{
      width: 100%;
      max-width: 90vw;
      bottom: 10%;
    }
    .footer{
        font-size: 11px;
    }
}